<div class="lightmode roomlistcomponent padding-inline-start-40px">
  <div class="title">
    <h1 class="component-header">Jojo's Tinychat Explorer</h1>
    <h3>
      <div class="p-grid">
        <div class="p-col-2">Rooms online: {{totalRooms()}}</div>
        <div class="p-col-2">Users online: {{totalUsers()}}</div>
        <div class="p-col-2">Mods online: {{totalModerators()}}</div>
        <div class="p-col-2">Users broadcasting: {{totalBroadcasting()}}</div>
        <div class="p-col-2">Users watching: {{totalWatching()}}</div>
      </div>
    </h3>
  </div>
  <div class="p-grid roompanel">
    <div class="p-col-6 roomlist">
      <span class="p-float-label">
        <input id="float-input" type="text" pInputText (ngModelChange)="onSearchRoomInput($event)"
          [(ngModel)]="searchRoomInputValue">
        <label for="float-input">Search for room</label>
      </span>
      <div>
        <div class="fieldsetdiv" *ngFor="let room of filteredRooms">
          <p-fieldset [legend]='room.room + " (#" + room.rank + ")"' (click)="selectRoom(room)"
            styleClass="defaultbackground marginfieldset">
            <div class="p-grid">
              <div class="p-col-3">Total Users: {{room.total_users}}</div>
              <div class="p-col-3">Moderators: {{room.moderators}}</div>
              <div class="p-col-3">Broadcasting: {{room.broadcasting}}</div>
              <div class="p-col-3">Watching: {{room.watching}}</div>
            </div>
          </p-fieldset>
        </div>
      </div>
    </div>
    <div class="p-col-6 roomdetails">
      <span class="p-float-label">
        <input id="float-input" type="text" pInputText (ngModelChange)="onSearchNameInput($event)"
          [(ngModel)]="searchNameInputValue">
        <label for="float-input">Search for user</label> &nbsp; <p-button label="Toggle Bold/Underline"
          (click)="boldUnderlineToggle = !boldUnderlineToggle"></p-button>
      </span>
      <div class="filteredRooms">
        <ul *ngFor="let room of usersInRooms">
          <li (click)="searchResultClicked(room.roomName)">User '{{room.username}}'' found in room '{{room.roomName}}'
          </li>
        </ul>
      </div>
      <div class="roomdetailsfieldset">
        <p-fieldset *ngIf="selectedRoom" [legend]='selectedRoom.room + " (#" + selectedRoom.rank + ")"'
          styleClass="defaultbackground">
          <!-- <div class="p-grid">
            <img src="{{selectedRoomDetails?.avatarUrl}}" />
          </div> -->
          <div class="p-grid">
            <div class="p-col-3">Total Users: {{selectedRoom.total_users}}</div>
            <div class="p-col-3">Moderators: {{selectedRoom.moderators}}</div>
            <div class="p-col-3">Broadcasting: {{selectedRoom.broadcasting}}</div>
            <div class="p-col-3">Watching: {{selectedRoom.watching}}</div>
          </div>
          <button pButton (click)="openSite(selectedRoom.room)" class="p-button-help"
            label="Enter {{selectedRoom.room}} now!"></button>
          <table>
            <thead>
              <th>Nickname</th>
              <th>Moderator</th>
              <th>Broadcasting</th>
            </thead>
            <tbody>
              <tr *ngFor="let user of selectedRoom.users">
                <td (click)="searchNameInputValue = user.nick; onSearchNameInput(user.nick)">
                  <span *ngIf="!boldUnderlineToggle">{{user.nick}}</span>
                  <span *ngIf="boldUnderlineToggle && !user.broadcasting && !user.oper">{{user.nick}}</span>
                  <u *ngIf="boldUnderlineToggle && user.broadcasting && !user.oper">{{user.nick}}</u>
                  <b *ngIf="boldUnderlineToggle && user.broadcasting && user.oper"><u>{{user.nick}}</u></b>
                  <b *ngIf="boldUnderlineToggle && !user.broadcasting && user.oper">{{user.nick}}</b>
                </td>
                <td>
                  {{user.oper ? 'Yes' : 'No'}}
                </td>
                <td>
                  {{user.broadcasting ? 'Yes' : 'No'}}
                </td>
              </tr>
            </tbody>
          </table>
        </p-fieldset>
      </div>
    </div>
  </div>
  <div class="updatetimes">
    <p>Last refresh: {{lastUpdateTime | date: 'yyyy-MM-dd HH:mm:ss'}}</p>
    <p>Last TC data change: {{lastDataChangedTime | date: 'yyyy-MM-dd HH:mm:ss'}}</p>
  </div>
  <div class="updatetimes2">
    <p>Times refreshed: {{timesRefreshed}}</p>
  </div>
</div>
