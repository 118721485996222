import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { Room } from './models/Room';
import { RoomDetails } from './models/RoomDetails';
import { RoomSearchResult } from './models/RoomSearchResult';
import { UsersResponse } from './models/UsersResponse';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  apiUrl = "https://tcexplorernewapi.azurewebsites.net/api";
  functionCode = "IbvZSyItXyL79Xh8Zfgm9cBWFOb0l52dtXKUD659eqwjAzFugAqpMg==";
  searchNameInputValue: string;
  searchNameInput: string;
  searchRoomInputValue: string;
  searchRoomInput: string;
  title = 'TinychatExplorer';
  rooms: Room[] = [];
  usersInRooms: RoomSearchResult[] = [];
  filteredRooms: Room[] = [];
  tinychatusersSubscription: Subscription;
  boldUnderlineToggle = true;
  selectedRoom: Room;
  selectedRoomDetails: RoomDetails;
  lastUpdateTime: Date;
  lastDataChangedTime: Date;
  requestRunning: boolean;
  timesRefreshed: number;

  constructor(private http: HttpClient) {
  }

  totalUsers() {
    return this.rooms.map(r => r.total_users).reduce((total, current) => total + current, 0);
  }

  totalModerators() {
    return this.rooms.map(r => r.moderators).reduce((total, current) => total + current, 0);
  }

  totalRooms() {
    return this.rooms?.length ?? 0;
  }

  totalBroadcasting() {
    return this.rooms.map(r => r.broadcasting).reduce((total, current) => total + current, 0);
  }

  totalWatching() {
    return this.rooms.map(r => r.watching).reduce((total, current) => total + current, 0);
  }

  ngOnInit(): void {
    this.fillRoomsList();
    interval(300000).subscribe(() => this.fillRoomsList());
  }

  onSearchNameInput(input: string) {
    this.searchNameInput = input;
    this.filterUserSearch();
  }

  filterUserSearch() {
    this.usersInRooms = [];
    if (!this.searchNameInput || this.searchNameInput.length < 1) { return; }
    this.rooms.forEach(room => {
      room.users.forEach(user => {
        if (user.nick.toLowerCase().includes(this.searchNameInput.toLowerCase())) {
          this.usersInRooms.push({ username: user.nick, roomName: room.room });
        }
      });
    });
    this.usersInRooms = this.usersInRooms.sort();
  }

  onSearchRoomInput(input: string) {
    this.searchRoomInput = input;
    this.filterRoomSearch();
  }

  filterRoomSearch() {
    this.filteredRooms = this.rooms;
    if (!this.searchRoomInput || this.searchRoomInput.length < 1) { return; }
    this.filteredRooms = [];
    this.rooms.forEach(room => {
      if (room.room.toLowerCase().includes(this.searchRoomInput.toLowerCase())) {
        this.filteredRooms.push(room);
      }
    });
    this.filteredRooms = this.filteredRooms.sort();
  }

  fillRoomsList() {
    if (this.requestRunning) {
      return;
    }
    this.requestRunning = true;
    const subscription = this.http.get(`${this.apiUrl}/tinychatusers?code=${this.functionCode}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
      }
    }).subscribe(
      res => {
        this.requestRunning = false;
        this.lastUpdateTime = new Date();
        const responseRooms = (res as UsersResponse)?.rooms ?? [];
        responseRooms.forEach(room => {
          room.users = room.users.sort((firstUser, secondUser) => {
            if (firstUser.oper && !secondUser.oper) {
              return -1;
            }
            if (!firstUser.oper && secondUser.oper) {
              return 1;
            }
            if (firstUser.broadcasting && !secondUser.broadcasting) {
              return -1;
            }
            if (!firstUser.broadcasting && secondUser.broadcasting) {
              return 1;
            }
            if (firstUser.nick.toLocaleLowerCase() < secondUser.nick.toLocaleLowerCase()) {
              return -1;
            }
            if (firstUser.nick.toLocaleLowerCase() > secondUser.nick.toLocaleLowerCase()) {
              return 1;
            }
            return 0;
          });
        });

        this.lastDataChangedTime = (res as UsersResponse)?.lastUpdatedAt;
        this.rooms = responseRooms;
        this.timesRefreshed = (res as UsersResponse)?.counter ?? 0;

        this.filterRoomSearch();
        this.filterUserSearch();
        if (this.selectedRoom) {
          const newSelectedRoom = this.rooms.find(r => r.room === this.selectedRoom.room);
          if (newSelectedRoom) {
            this.selectedRoom = newSelectedRoom;
          }
        }
        subscription.unsubscribe();
      }, err => {
        setTimeout(() => {
          this.requestRunning = false;
          this.fillRoomsList();
        }, 1000);
      });
  }

  openSite(roomName: string) {
    window.open('https://tinychat.com/room/' + roomName, '_blank');
  }

  searchResultClicked(roomName: string) {
    this.selectedRoom = this.rooms.find(r => r.room === roomName);
    this.loadRoomDetails(roomName);
  }

  selectRoom(room: Room) {
    this.selectedRoom = room;
    this.loadRoomDetails(room.room);
  }

  loadRoomDetails(roomName: string) {
    // this.selectedRoomDetails = null;
    // const subscription = this.http.get<RoomDetails>(`${this.apiUrl}/TinychatRoomDetails?roomName=${roomName}`)
    //   .subscribe(res => {
    //     this.selectedRoomDetails = res;
    //     subscription.unsubscribe();
    //   });
  }
}
